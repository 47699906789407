import React from 'react'
import GlobalPlatformEdit from './GlobalPlatformEdit'
import Screen from '../../components/Screen'
import { globalPlatformUpdate } from '../../requests/tools'
import { useLtiGlobalPlatform } from '../../hooks/useLtiGlobalPlatform'
import { useAsyncAction } from '../../hooks/useAsyncAction'
import { connectAppBar } from '../../hooks/setsAppBar'
import GateExists from '../../components/GateExists'

function GlobalPlatformsEditContainer({ match, history, setAppBar }) {
  setAppBar('LTI 1.3 Global Platforms')

  const {
    globalPlatform,
    isFetchingGlobalPlatform,
    globalPlatformError
  } = useLtiGlobalPlatform(match.params.id)

  const [handleSubmit, submitting, submitError] = useAsyncAction(
    async data => {
      await globalPlatformUpdate(match.params.id, data)
    },
    async () => {
      alert('Platform saved!')
      history.push('/tools/lti-1.3-platforms')
    }
  )

  return (
    <Screen
      isLoading={isFetchingGlobalPlatform}
      error={globalPlatformError}
      name="Edit global LTI 1.3 platform">
      <GateExists exists={globalPlatform}>
        <GlobalPlatformEdit
          platform={globalPlatform}
          error={submitError}
          submitting={submitting}
          onSubmit={handleSubmit}
        />
      </GateExists>
    </Screen>
  )
}

export default connectAppBar(GlobalPlatformsEditContainer)
