import { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'

export const useLazyRequest = (requestFunc, ...requestParams) => {
  const [result, setResult] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const [error, setError] = useState(null)

  const sourceRef = useRef(null)

  const execute = useCallback(async () => {
    sourceRef.current = axios.CancelToken.source()

    setIsFetching(true)
    try {
      const result = await requestFunc(...requestParams, {
        cancelToken: sourceRef.current.token
      })
      setResult(result.data)
      return result
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log('Request canceled:', err.message)
      } else {
        setError(err)
        throw err
      }
    } finally {
      setIsFetching(false)
    }
  }, [requestFunc, ...requestParams])

  useEffect(() => {
    return () => {
      if (sourceRef.current) {
        sourceRef.current.cancel('Request canceled due to unmount')
      }
    }
  }, [])

  return [execute, { isFetching, result, error }]
}
