import { globalPlatformsFetch } from '../requests/tools'
import { useRequest } from './useRequest'

export const useLtiGlobalPlatforms = () => {
  const { result, isFetching, error } = useRequest(globalPlatformsFetch)

  return {
    globalPlatforms: result,
    isFetchingGlobalPlatforms: isFetching,
    globalPlatformsError: error
  }
}
