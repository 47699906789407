import React from 'react'
import GlobalPlatformCreate from './GlobalPlatformCreate'
import Screen from '../../components/Screen'
import { globalPlatformCreate } from '../../requests/tools'
import { useAsyncAction } from '../../hooks/useAsyncAction'
import { connectAppBar } from '../../hooks/setsAppBar'

function GlobalPlatformsCreateContainer({ history, setAppBar }) {
  setAppBar('Create LTI 1.3 Global Platform')
  const [handleSubmit, submitting, error] = useAsyncAction(
    async data => {
      await globalPlatformCreate(data)
    },
    async () => {
      alert('Platform created!')
      history.push('/tools/lti-1.3-platforms')
    }
  )

  return (
    <Screen name="global LTI 1.3 platform create">
      <GlobalPlatformCreate
        error={error}
        submitting={submitting}
        onSubmit={handleSubmit}
      />
    </Screen>
  )
}

export default connectAppBar(GlobalPlatformsCreateContainer)
