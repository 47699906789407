import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5'
    }
  },
  overrides: {
    MuiCard: {
      root: {
        borderRadius: 0
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#c66c17'
      }
    }
  }
})

export default theme
