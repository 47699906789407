import React from 'react'
import PropTypes from 'prop-types'
import LtiKeyInputRaw from '../../LtiKeyInput'

class LtiKeyInput extends React.Component {
  handleChange = data => {
    this.props.onChange(this.props.id, data)
  }

  render() {
    return <LtiKeyInputRaw {...this.props} onChange={this.handleChange} />
  }
}

LtiKeyInput.defaultProps = {
  max: 100
}

LtiKeyInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  max: PropTypes.number
}

export default LtiKeyInput
