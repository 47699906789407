import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import CopyIcon from 'mdi-react/ContentCopyIcon'
import IconButton from '@material-ui/core/IconButton'

export const CopyContentButton = ({ content }) => {
  const [title, setTitle] = useState('Copy to Clipboard')
  return (
    <Tooltip
      title={title}
      placement="top"
      onClick={() => {
        navigator.clipboard.writeText(content)
        setTitle(`Copied Channel ID: ${content}`)
      }}>
      <IconButton>
        <CopyIcon />
      </IconButton>
    </Tooltip>
  )
}

CopyContentButton.propTypes = {
  content: PropTypes.string
}
