import React from 'react'
import PlusIcon from 'mdi-react/PlusIcon'
import GlobalPlatforms from './GlobalPlatforms'
import Screen from '../../components/Screen'
import { connectAppBar } from '../../hooks/setsAppBar'
import { useLtiGlobalPlatforms } from '../../hooks/useLtiGlobalPlatforms'
import GateExists from '../../components/GateExists'

function GlobalPlatformsContainer({ history, setAppBar }) {
  const {
    globalPlatforms,
    isFetchingGlobalPlatforms,
    globalPlatformsError
  } = useLtiGlobalPlatforms()

  setAppBar(
    'LTI 1.3 Global Platforms',
    () => history.push('/tools/lti-1.3-platforms/create'),
    <PlusIcon />
  )

  return (
    <Screen
      name="global LTI 1.3 platforms"
      isLoading={isFetchingGlobalPlatforms}
      error={globalPlatformsError}>
      <GateExists exists={globalPlatforms}>
        <GlobalPlatforms
          onEdit={id => history.push(`/tools/lti-1.3-platforms/${id}`)}
          platforms={globalPlatforms}
        />
      </GateExists>
    </Screen>
  )
}

export default connectAppBar(GlobalPlatformsContainer)
