import { fetchOnlineLicenseKey } from '../requests/licenses'
import { useLazyRequest } from './useLazyRequest'

export const useLazyGenerateOnlineLicenseKey = licensePrefix => {
  const [execute, { result, isFetching, error }] = useLazyRequest(
    fetchOnlineLicenseKey,
    licensePrefix
  )

  return [
    execute,
    {
      onlineLicenseKey: result,
      isFetchingOnlineLicenseKey: isFetching,
      onlineLicenseKeyError: error
    }
  ]
}
