import React from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Divider from '@material-ui/core/Divider'
import GlobalPlatform from './GlobalPlatform'

const GlobalPlatforms = ({ platforms, onEdit }) => (
  <div>
    <Breadcrumbs links={[{ title: 'Tools', href: '/tools' }]} />
    <Card>
      <CardHeader className="title" title="LTI 1.3 Global Platforms" />
      {platforms.map((platform, i) => (
        <div key={platform.id}>
          <GlobalPlatform
            platform={platform}
            onEdit={() => onEdit(platform.id)}
          />
          {i < platforms.length - 1 && <Divider />}
        </div>
      ))}
    </Card>
  </div>
)

GlobalPlatforms.propTypes = {
  onEdit: PropTypes.func.isRequired,
  platforms: PropTypes.arrayOf(PropTypes.shape({}))
}

export default GlobalPlatforms
