import React from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Form from '../channel-platform-configure/ChannelPlatformConfigureForm'
import ServerError from '../../components/ServerError'

const GlobalPlatforms = ({ error, onSubmit }) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Tools', href: '/tools' },
        { title: 'Manage LTI 1.3 Platforms', href: '/tools/lti-1.3-platforms' }
      ]}
    />
    <Card>
      <CardHeader className="title" title="Create LTI 1.3 Platform" />
      <CardContent className="description">
        {error && <ServerError error={error} />}
        <Form
          includeName
          submitText="Create Configuration"
          onSubmit={onSubmit}
        />
      </CardContent>
    </Card>
  </div>
)

GlobalPlatforms.propTypes = {
  error: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired
}

export default GlobalPlatforms
