import React, { Component } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Drawer from '../containers/ConnectDrawer'
import NavDrawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from 'mdi-react/MenuIcon'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core'
import DownloadProgress from './DownloadProgress'

const drawerWidth = 240

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  appFrame: {
    height: '100%',
    zIndex: 1,
    position: 'relative',
    display: 'flex',
    width: '100%'
  },
  appBar: {
    position: 'fixed',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: drawerWidth
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  hide: {
    display: 'none'
  },
  drawerPaper: {
    position: 'fixed',
    width: drawerWidth,
    height: '100vh'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 500,
    padding: theme.spacing(5),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: 0
  },
  contentShift: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  grow: {
    flexGrow: 1
  }
})

class AppLayout extends Component {
  componentDidMount() {
    if (this.props.isUserLoggedIn) this.fetchAllData()
  }

  componentDidUpdate(prevProps) {
    //  Check if user has gone from logged out state to logged in state
    if (!prevProps.isUserLoggedIn && this.props.isUserLoggedIn)
      this.fetchAllData()
  }

  fetchAllData() {
    this.props.awaitUserData()
    this.props.fetchNotifications()
    this.props.fetchOrganizations()
    this.props.fetchUsers()
    this.props.fetchCourses()
    this.props.fetchCourseFolders()
    this.props.fetchTags()
    this.props.fetchMyDownloads()
  }

  togglePinnedNavDrawer = () => {
    this.props.setNavDrawerPinnedState(!this.props.isNavDrawerPinned)
  }

  render() {
    document.title = this.props.organization
    const { isNavDrawerPinned, classes, downloadsState } = this.props

    return (
      <div className={classes.root}>
        <DownloadProgress state={downloadsState} />
        {this.props.showAppBar && (
          <div className={classes.appFrame}>
            <AppBar
              className={classNames(classes.appBar, {
                [classes.appBarShift]: isNavDrawerPinned
              })}>
              <Toolbar>
                <IconButton
                  onClick={this.togglePinnedNavDrawer}
                  color="inherit"
                  className={classes.menuButton}>
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" noWrap>
                  {this.props.title}
                </Typography>
                <div className={classes.grow} />
                {this.props.appBarChildren}
                {this.props.canAdd && (
                  <IconButton
                    data-testid={'handleRightButton'}
                    onClick={this.props.handleRight}
                    color="inherit">
                    {this.props.rightIcon}
                  </IconButton>
                )}
              </Toolbar>
            </AppBar>
            <NavDrawer
              open={isNavDrawerPinned}
              variant="persistent"
              onClose={this.togglePinnedNavDrawer}
              classes={{ paper: classes.drawerPaper }}>
              <Drawer user={this.props.user} />
            </NavDrawer>

            <div
              className={classNames(classes.content, {
                [classes.contentShift]: isNavDrawerPinned
              })}>
              <div className={classes.drawerHeader} />
              {this.props.children}
            </div>
          </div>
        )}

        {!this.props.showAppBar && <div>{this.props.children}</div>}
      </div>
    )
  }
}

AppLayout.propTypes = {
  setNavDrawerPinnedState: PropTypes.func.isRequired,
  awaitUserData: PropTypes.func.isRequired,
  fetchNotifications: PropTypes.func.isRequired,
  fetchOrganizations: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  fetchCourses: PropTypes.func.isRequired,
  fetchCourseFolders: PropTypes.func.isRequired,
  fetchFiles: PropTypes.func.isRequired,
  fetchMyDownloads: PropTypes.func.isRequired,
  fetchTags: PropTypes.func.isRequired,
  isUserLoggedIn: PropTypes.bool,
  isNavDrawerPinned: PropTypes.bool,
  showAppBar: PropTypes.bool,
  title: PropTypes.string,
  organization: PropTypes.string,
  isLoggedInUserSuperAdmin: PropTypes.bool,
  canAdd: PropTypes.bool,
  location: PropTypes.string,
  rightIcon: PropTypes.element,
  handleRight: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]),
  appBarChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]),
  user: PropTypes.object,
  classes: PropTypes.object.isRequired,
  downloadsState: PropTypes.object
}

export default withStyles(styles)(AppLayout)
