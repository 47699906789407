import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from 'mdi-react/EditIcon'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'

const Container = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  padding: 24px;

  .details {
    flex-grow: 1;
    width: 100%;
  }

  .info {
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .name {
    font-weight: bold;
    margin-right: 10px;
    line-height: 28px;
  }

  .info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    font-weight: 300;

    span {
      margin-right: 10%;
      margin-bottom: 5px;
    }
  }

  .edit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

const GlobalPlatform = ({ platform, onEdit }) => (
  <>
    <Container>
      <div className="details">
        <div className="info">
          <span className="name">{platform.name}</span>
        </div>
      </div>
      <div className="edit">
        <IconButton onClick={() => onEdit()}>
          <EditIcon />
        </IconButton>
      </div>
    </Container>
    <div
      style={{
        marginTop: '-24px',
        padding: '0 24px 24px 24px',
        clear: 'both'
      }}>
      <Grid container spacing={3}>
        <Grid item md={6} lg={3}>
          Issuer: <code>{platform.issuer}</code>
        </Grid>
        <Grid item md={6} lg={9}>
          Client ID: <code>{platform.client_id}</code>
        </Grid>
      </Grid>
    </div>
  </>
)

GlobalPlatform.propTypes = {
  onEdit: PropTypes.func.isRequired,
  platform: PropTypes.shape({
    name: PropTypes.string.isRequired,
    issuer: PropTypes.string.isRequired,
    client_id: PropTypes.string.isRequired
  })
}

export default GlobalPlatform
