import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Link from '../../components/Link'
import can from '../../utilities/can'

const tools = [
  {
    href: '/tools/merge-course',
    text: 'Merge Course',
    permission: 'MERGE_COURSE'
  },
  {
    href: '/tools/surveys',
    text: 'Manage Surveys',
    permission: 'SURVEYS_MANAGE'
  },
  {
    href: '/tools/bulk-copy-courses',
    text: 'Bulk Copy Courses',
    permission: 'CHANNELS_MANAGE_ALL'
  },
  {
    href: '/tools/lti-1.3-platforms',
    text: 'Manage Global LTI 1.3 Platforms',
    permission: 'CHANNELS_MANAGE_ALL'
  },
  {
    href: '/tools/partner-course-usage-reporting',
    text: 'Partner Usage - Course Reporting',
    permission: 'PARTNER_USAGE_REPORT'
  }
]

const Tools = ({ user }) => (
  <div>
    <Card className="tools">
      <CardHeader className="title" title="Tools" />
      <CardContent>
        <ul>
          {tools.map(
            tool =>
              can(user, tool.permission) && (
                <li key={tool.href}>
                  <Link to={tool.href}>{tool.text}</Link>
                </li>
              )
          )}
          {can(user, 'USERS_EDIT_ALL') && (
            <li>
              <Link to="/tools/genius-remove-final-grade">
                Genius - Remove Final Grade
              </Link>
            </li>
          )}
        </ul>
      </CardContent>
    </Card>
  </div>
)

Tools.propTypes = {
  user: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
}

export default Tools
