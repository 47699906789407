import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextInput from '../form/elements/TextInput'
import styled from 'styled-components'
import grey from '@material-ui/core/colors/grey'
import moment from 'moment-timezone'
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon'
import LockIcon from 'mdi-react/LockIcon'
import LockOpenIcon from 'mdi-react/LockOpenIcon'

import { Button, IconButton, Tooltip, FormLabel } from '@material-ui/core'
import { DateInput } from '../form/elements/DateInput'

const utcMoment = moment.utc

const TermLineBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75em;

  .numberOfMonths {
    min-width: 10rem;
  }

  .term-field-label {
    width: 133%; // MUI applies a scale transform to the label, this is required to account for that
  }

  .end-field-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

const LockButton = styled(Button)`
  && {
    height: 2em;
    width: 2em;
    min-width: 0;
    padding: 0;
  }
`

const getDateFromInitPlusMonths = (initialDate, numberOfMonths) =>
  utcMoment(initialDate)
    .add(numberOfMonths, 'M')
    .format('YYYY-MM-DD')

const getDateString = date => (date ? utcMoment(date).format('YYYY-MM-DD') : '')

const TermLine = ({
  termKey,
  start,
  end,
  numberOfMonths,
  specialExtensionEnd,
  multiplayerEnd,
  handleTermsChange,
  removeTerm
}) => {
  const [editLocked, setEditLocked] = useState(true)
  const updateTerm = (id, value) => {
    const term = {
      start,
      end,
      specialExtensionEnd,
      multiplayerEnd
    }
    term[id] = value
    handleTermsChange(termKey, term)
  }

  return (
    <TermLineBox>
      <DateInput
        id="start"
        label="Start Date"
        value={getDateString(start)}
        onChange={updateTerm}
      />
      <TextInput
        classNames="numberOfMonths"
        type="number"
        label="Number of Months"
        value={numberOfMonths}
        onChange={(id, value) => {
          updateTerm('end', getDateFromInitPlusMonths(start, value))
        }}
      />
      <DateInput
        id="end"
        disabled={editLocked}
        label={
          <div className="end-field-label">
            <span>End Date</span>
            <Tooltip
              title={`${
                editLocked ? 'Enable' : 'Disable'
              } manual end date editing`}
              placement="top">
              <LockButton onClick={() => setEditLocked(prev => !prev)}>
                {editLocked ? <LockIcon color={grey[500]} /> : <LockOpenIcon />}
              </LockButton>
            </Tooltip>
          </div>
        }
        buttonProps={{
          style: {
            marginRight: '0.5em'
          }
        }}
        labelProps={{
          classes: {
            root: 'term-field-label'
          }
        }}
        value={getDateString(end)}
        onChange={updateTerm}
      />
      <DateInput
        id="specialExtensionEnd"
        label="Special Extension End"
        value={getDateString(specialExtensionEnd)}
        onChange={updateTerm}
        style={{ minWidth: '11em' }}
      />
      <DateInput
        id="multiplayerEnd"
        label="Multiplayer End"
        value={getDateString(multiplayerEnd)}
        onChange={updateTerm}
      />
      <Tooltip title="Remove Term" placement="right">
        <IconButton color="secondary" onClick={() => removeTerm(termKey)}>
          <CloseCircleOutlineIcon />
        </IconButton>
      </Tooltip>
    </TermLineBox>
  )
}

TermLine.propTypes = {
  termKey: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  numberOfMonths: PropTypes.number.isRequired,
  specialExtensionEnd: PropTypes.string,
  multiplayerEnd: PropTypes.string,
  handleTermsChange: PropTypes.func,
  removeTerm: PropTypes.func
}

const AddTermButton = styled(Button)`
  && {
    width: fit-content;
    min-width: 25%;
  }
`

const TermBox = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: t;
  gap: 0.75em;
`

const NoTerms = styled.div`
  border-radius: 4px;
  border: 1px solid ${grey[400]};
  padding: 0.5em;
  width: fit-content;
  min-width: 25%;
  color: ${grey[400]};
  background: ${grey[50]};
  text-align: center;
`

const Terms = ({ terms, olk, onLicenseChange }) => {
  const handleTermsChange = async (termKey, updatedTerm) => {
    const updatedTerms = [...terms].reduce((allTerms, currentTerm, index) => {
      if (`${index}-${olk}` === termKey) {
        allTerms.push(updatedTerm)
      } else allTerms.push(currentTerm)
      return allTerms
    }, [])
    await onLicenseChange('terms', updatedTerms)
  }

  const addTerm = async () => {
    const previousTerm = terms?.at(-1) || null

    const newTerm = {
      ...(previousTerm
        ? {
            start: getDateString(new Date(previousTerm.end)),
            end: getDateFromInitPlusMonths(previousTerm.end, 1)
          }
        : {
            start: getDateString(new Date()),
            end: getDateFromInitPlusMonths(new Date(), 1)
          }),
      specialExtensionEnd: null,
      multiplayerEnd: null
    }
    await onLicenseChange('terms', terms ? [...terms, newTerm] : [newTerm])
  }

  const removeTerm = async termKey => {
    if (confirm('Are you sure you want to remove this term?')) {
      const updatedTerms = [...terms].filter((term, index) => {
        return `${index}-${olk}` !== termKey
      })
      await onLicenseChange('terms', updatedTerms)
    }
  }

  return (
    <TermBox>
      <FormLabel style={{ marginTop: '1em' }}>License Terms</FormLabel>
      {terms && terms?.length > 0 ? (
        terms?.map((term, index) => (
          <TermLine
            key={`${index}-${olk}-${term.start}`} // for the react key, term.start provides an additional level of specificity
            termKey={`${index}-${olk}`}
            handleTermsChange={handleTermsChange}
            removeTerm={removeTerm}
            numberOfMonths={Math.round(
              utcMoment(term.end).diff(term.start, 'M', true)
            )}
            {...term}
          />
        ))
      ) : (
        <NoTerms>No Terms Set For License</NoTerms>
      )}
      <AddTermButton variant="outlined" onClick={addTerm}>
        Add Term
      </AddTermButton>
    </TermBox>
  )
}

Terms.propTypes = {
  terms: PropTypes.array,
  olk: PropTypes.string.isRequired,
  onLicenseChange: PropTypes.func.isRequired
}

export default Terms
