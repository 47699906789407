import { globalPlatformFetch } from '../requests/tools'
import { useRequest } from './useRequest'

export const useLtiGlobalPlatform = id => {
  const { result, isFetching, error } = useRequest(globalPlatformFetch, id)

  return {
    globalPlatform: result,
    isFetchingGlobalPlatform: isFetching,
    globalPlatformError: error
  }
}
