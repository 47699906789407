import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import MagnifyIcon from 'mdi-react/MagnifyIcon'

const StyledPaper = styled(Paper)`
  padding-right: 12px;
`

const StyledIcon = styled(MagnifyIcon)`
  padding-left: 8px;
  padding-right: 5px !important;
`

const Search = ({ className, handleChange, autoFocus }) => (
  <StyledPaper className={className} square={true}>
    <TextField
      id="search"
      type="search"
      fullWidth={true}
      onChange={event => handleChange(event.target.value)}
      autoFocus={autoFocus}
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <StyledIcon />
          </InputAdornment>
        )
      }}
    />
  </StyledPaper>
)

const SearchField = styled(Search)`
  width: 75%;
  margin: 0 auto;
  margin-bottom: 42px;
  border-radius: 3px;
`

Search.propTypes = {
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  handleChange: PropTypes.func.isRequired
}

export default SearchField
