import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import OrganizationUsersEdit from './OrganizationUsersEdit'
import Screen from '../../../components/Screen'
import GateExists from '../../../components/GateExists'
import { editUser, deleteUserAwait } from '../../../actions/users'
import { setAppBarState } from '../../../actions/ui'
import can from '../../../utilities/can'
import { useChannels } from '../../../hooks/useChannels'
import { useOrganization } from '../../../hooks/useOrganization'
import { useOrgUser } from '../../../hooks/useOrgUser'

const OrganizationUsersEditContainer = props => {
  const {
    onSubmit,
    onDelete,
    organizationId,
    userId,
    isLoading,
    error,
    setAppBarState
  } = props

  const [submitError, setSubmitError] = useState(null)
  const { channels, isFetchingChannels, channelsError } = useChannels(
    organizationId
  )
  const { organization, isFetchingOrg, orgError } = useOrganization(
    organizationId
  )
  const { user, isFetchingUser, userError } = useOrgUser(
    organizationId,
    userId,
    true,
    channels
  )

  useEffect(() => {
    setAppBarState({
      visible: true,
      title: 'Edit User',
      canAdd: false
    })
  }, [])

  const handleSubmit = async data => {
    try {
      await onSubmit(data)
    } catch (error) {
      setSubmitError(error)
    }
  }

  const handleDelete = async () => {
    return onDelete(user)
  }

  return (
    <Screen
      name="organization edit user"
      isLoading={
        isLoading || isFetchingChannels || isFetchingOrg || isFetchingUser
      }
      error={error || channelsError || orgError || userError}
      renderContent={() => (
        <GateExists exists={channels && user && organization}>
          <OrganizationUsersEdit
            user={user}
            channels={channels}
            organization={organization}
            onSubmit={handleSubmit}
            onDelete={handleDelete}
            error={submitError}
            {...props}
          />
        </GateExists>
      )}
    />
  )
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: !state.auth.userData.id,
  organizationId: ownProps.match.params.organization,
  userId: ownProps.match.params.id,
  canEditChannels:
    state.auth.userData.id &&
    can(state.auth.userData, 'CHANNEL_USERS_MANAGE_ORG')
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: state => dispatch(setAppBarState(state)),
  onDelete: user => dispatch(deleteUserAwait(user)),
  onSubmit: data =>
    dispatch(
      editUser({
        id: ownProps.match.params.id,
        organization: ownProps.match.params.organization,
        ...data
      })
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationUsersEditContainer)
