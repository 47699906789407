import React from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Form from '../channel-platform-configure/ChannelPlatformConfigureForm'
import ServerError from '../../components/ServerError'

const GlobalPlatformEdit = ({ platform, error, onSubmit }) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Tools', href: '/tools' },
        { title: 'Manage LTI 1.3 Platforms', href: '/tools/lti-1.3-platforms' }
      ]}
    />
    <Card>
      <CardHeader
        className="title"
        title={`Update LTI 1.3 Platform: ${platform.name}`}
      />
      <CardContent className="description">
        {error && <ServerError error={error} />}
        <Form platform={platform} includeName onSubmit={onSubmit} />
      </CardContent>
    </Card>
  </div>
)

GlobalPlatformEdit.propTypes = {
  error: PropTypes.shape({}),
  platform: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  onSubmit: PropTypes.func.isRequired
}

export default GlobalPlatformEdit
