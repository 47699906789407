import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import FixedFooterDialog from '../components/FixedFooterDialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import styles from '../styles'

class NotificationFileDialog extends Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    toggleActive: PropTypes.func.isRequired,
    input: PropTypes.object.isRequired,
    addFile: PropTypes.func.isRequired,
    fetchFiles: PropTypes.func.isRequired,
    isAdding: PropTypes.bool.isRequired,
    notification: PropTypes.object.isRequired
  }

  state = {
    file: null,
    inputErrors: {
      fileError: {
        isError: false,
        message: 'Please choose a file to upload. '
      }
    }
  }

  initialState = { ...this.state }

  onCloseResetInitialState = Object.assign({}, this.initialState, {
    isDialogClosing: true
  })

  cancel = () => {
    this.props.toggleActive()
  }

  validFields = () => {
    return !this.state.inputErrors.fileError.isError
  }

  setFieldErrorValues = async () => {
    if (!this.state.file)
      await this.setFieldError('file', 'Please upload a file')
  }

  onSubmit = async () => {
    await this.setFieldErrorValues()
    if (!this.validFields()) return

    const params = {
      name: this.state.file.name
    }

    await this.props.addFile(params, this.state.file)
    this.props.toggleActive({ file: null, type: '' })

    this.setState(this.initialState)
  }

  setFieldError = (fieldName, msg) => {
    let error = Object.assign({}, this.state.inputErrors[fieldName + 'Error'])
    error.isError = !!msg
    error.message = msg

    this.setState({
      inputErrors: {
        ...this.state.inputErrors,
        [fieldName + 'Error']: error
      }
    })
  }

  handleChange = (field, value) => {
    this.setState({
      [field]: value
    })

    if (value.length === 0) this.setFieldError(field, true)
    else this.setFieldError(field, false)
  }

  validateUpload = async file => {
    const validExtensions = ['zip', 'png', 'csv', 'pdf']
    const extension = file.name
      .split('.')
      .slice(-1)
      .join('.')
      .toLowerCase()

    if (!validExtensions.includes(extension)) {
      this.setFieldError(
        'file',
        `Please upload a valid ${validExtensions.join(', ')}`
      )
      return
    }

    const MAX_SIZE = 100 * 1024 * 1024
    if (file.size >= MAX_SIZE) {
      this.setFieldError('file', 'Please upload a file smaller than 100MB')
      return
    }

    this.setFieldError('file', false)
    this.setState({ file })
  }

  onDrop = async accepted => {
    if (!accepted || accepted.length === 0) return
    if (accepted.length > 1) {
      this.setFieldError('file', 'Please upload one file at a time')
      return
    }
    this.validateUpload(accepted[0])
  }

  render() {
    return (
      <div>
        <FixedFooterDialog
          active={this.props.active}
          actions={[
            {
              label: 'Cancel',
              style: !this.props.isAdding
                ? styles.buttons
                : styles.buttons_disabled,
              onClick: this.cancel,
              disabled: this.props.isAdding
            },
            {
              label: 'Save',
              variant: 'contained',
              style: !this.props.isAdding
                ? styles.buttons
                : styles.buttons_disabled,
              onClick: this.onSubmit,
              disabled: this.props.isAdding
            }
          ]}
          title="Attach file"
          className="fileDialog">
          <div className="radio__group__error">
            {this.state.inputErrors.fileError.isError &&
              this.state.inputErrors.fileError.message}
          </div>
          <Dropzone
            multiple={false}
            className="dropzone"
            acceptClassName="dropzoneAccept"
            onDrop={accepted => this.onDrop(accepted)}>
            <div className="file__content">
              {this.state.file && (
                <div className="file__content__info">
                  <p>{this.state.file.name}</p>
                  <p>{this.state.file.size} bytes</p>
                </div>
              )}
              {this.props.isAdding && (
                <CircularProgress className="loading" size={40} />
              )}
            </div>
          </Dropzone>
        </FixedFooterDialog>
        <style>{`
          .fileDialog .dropzone {
            border: 1px dashed grey;
            margin-bottom: 16px;
            cursor: pointer;
            min-height: 54px;
            font-size: 14px;
            color: #757575;
          }
          .fileDialog .dropzoneAccept {
            border: 1px solid #56ff47;
          }
          .fileDialog .file__content {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 50px;
            justify-content: ${
              this.props.isAdding ? 'space-between' : 'flex-start'
            };
          }
          .fileDialog .file__content__info {
            margin-left: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
          }
          .fileDialog .file__content__info p {
            margin: 0;
          }
          .fileDialog .file__content__info h4 {
            margin: 0;
          }
          .fileDialog .radio__group {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;
          }
          .fileDialog .radio__group__error {
            font-size: 12px;
            color: red;
            text-align: center;
            margin-bottom: 10px;
          }
          .fileDialog .versions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        `}</style>
      </div>
    )
  }
}

export default NotificationFileDialog
