import axios from 'axios'
import { getToken } from '../auth'

export async function fetchOnlineLicenseKey(licensePrefix, options) {
  const res = await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_REMOTE}/licenses/keys/${licensePrefix}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() },
    ...(options?.cancelToken ? { cancelToken: options.cancelToken } : {})
  })
  return res.data
}
