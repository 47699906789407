import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

class TextInput extends Component {
  static defaultProps = {
    value: '',
    type: 'text',
    placeholder: '',
    fieldSuffix: '',
    valid: true
  }

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange({ target: { id, value } }) {
    const { onChange } = this.props
    onChange(id, value)
  }

  render() {
    const {
      id,
      value,
      label,
      type,
      placeholder,
      fieldSuffix,
      valid,
      multiline,
      onBlur,
      classNames,
      disabled,
      labelProps
    } = this.props
    const validClass = valid ? '' : 'invalid'
    return (
      <div className={classNames ? `text-input ${classNames}` : 'text-input'}>
        <TextField
          id={id}
          value={value || ''}
          label={label}
          placeholder={placeholder}
          className={validClass}
          onChange={this.handleChange}
          onBlur={onBlur ? onBlur : () => {}}
          type={type}
          multiline={multiline}
          fullWidth
          disabled={disabled}
          InputLabelProps={{
            shrink: true,
            ...labelProps
          }}
        />
        {fieldSuffix !== '' ? (
          <span className="field-suffix">{fieldSuffix}</span>
        ) : null}
      </div>
    )
  }
}

TextInput.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  fieldSuffix: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  multiline: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  valid: PropTypes.bool,
  disabled: PropTypes.bool,
  classNames: PropTypes.string,
  labelProps: PropTypes.object
}

TextInput.displayName = 'TextInput'

export default TextInput
