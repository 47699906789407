import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import OrganizationUsersCreate from './OrganizationUsersCreate'
import Screen from '../../../components/Screen'
import GateExists from '../../../components/GateExists'
import { createUser } from '../../../actions/users'
import { setAppBarState } from '../../../actions/ui'
import can from '../../../utilities/can'
import { useChannels } from '../../../hooks/useChannels'
import { useOrganization } from '../../../hooks/useOrganization'

const OrganizationUsersCreateContainer = props => {
  const { onSubmit, organizationId, isLoading, error, setAppBarState } = props

  const [submitError, setSubmitError] = useState(null)
  const { organization, isFetchingOrg, orgError } = useOrganization(
    organizationId
  )
  const { channels, isFetchingChannels, channelsError } = useChannels(
    organizationId
  )

  useEffect(() => {
    setAppBarState({
      visible: true,
      title: 'Create User',
      canAdd: false
    })
  }, [])

  const handleSubmit = async data => {
    try {
      if (data.channels && !data.channels.length) {
        if (
          !confirm(
            'You are about to create a user without any channels. Are you sure you wish to continue?'
          )
        ) {
          return
        }
      }

      await onSubmit(data)
    } catch (err) {
      setSubmitError(err)
    }
  }

  return (
    <Screen
      name="organization create user"
      isLoading={isLoading || isFetchingChannels || isFetchingOrg}
      error={error || channelsError || orgError}
      renderContent={() => (
        <GateExists exists={channels && organization}>
          <OrganizationUsersCreate
            channels={channels}
            organization={organization}
            onSubmit={handleSubmit}
            error={submitError}
            {...props}
          />
        </GateExists>
      )}
    />
  )
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: !state.auth.userData.id || state.organizations.isFetching,
  organizationId: ownProps.match.params.organization,
  canEditChannels:
    state.auth.userData.id &&
    can(state.auth.userData, 'CHANNEL_USERS_MANAGE_ORG')
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: state => dispatch(setAppBarState(state)),
  onSubmit: data =>
    dispatch(
      createUser({
        organization: ownProps.match.params.organization,
        ...data
      })
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationUsersCreateContainer)
