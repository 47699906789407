import { useEffect } from 'react'
import { connect } from 'react-redux'
import { setAppBarState } from '../actions/ui'

const setsAppBar = (dispatch, title, onClick, icon, deps = []) => {
  useEffect(() => {
    dispatch(
      setAppBarState({
        visible: true,
        title,
        rightIcon: icon,
        canAdd: !!icon,
        handleRight: onClick || (() => {})
      })
    )
  }, deps)
}

/**
 * Helper to expose setAppBar to components without full blown redux boilerplate
 */
export const connectAppBar = Component => {
  const mapDispatchToProps = dispatch => ({
    setAppBar: (title, onClick, icon, deps) => {
      setsAppBar(dispatch, title, onClick, icon, deps)
    }
  })
  return connect(null, mapDispatchToProps)(Component)
}
