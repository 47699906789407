import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import ChannelPlatformConfigure from './ChannelPlatformConfigure'
import Screen from '../../components/Screen'
import { getChannel } from '../../selectors'
import {
  channelsFetchAwait,
  channelPlatformConfigure
} from '../../actions/channels'
import { isSuperAdmin } from '../../selectors/auth'
import GateExists from '../../components/GateExists'
import { useLtiConfig } from '../../hooks/useLtiConfig'
import { useOrganization } from '../../hooks/useOrganization'

const ChannelPlatformConfigureContainer = props => {
  const {
    fetchChannels,
    isLoading,
    channel,
    error,
    organizationId,
    onMultiTenant
  } = props
  const { ltiConfig, isFetchingLti, ltiError } = useLtiConfig()
  const { organization, isFetchingOrg, orgError } = useOrganization(
    organizationId
  )

  useEffect(() => {
    fetchChannels()
  }, [])

  return (
    <Screen
      name="platform configuration"
      isLoading={isLoading || isFetchingLti || isFetchingOrg}
      error={error || ltiError || orgError}>
      <GateExists exists={channel && organization}>
        <ChannelPlatformConfigure
          channel={channel}
          config={ltiConfig}
          organization={organization}
          onMultiTenant={onMultiTenant}
          {...props}
        />
      </GateExists>
    </Screen>
  )
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: state.channels.isFetching,
  isSuperAdmin: isSuperAdmin(state),
  organizationId: ownProps.match.params.organization,
  channel: getChannel(ownProps.match.params.channel)(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchChannels: () => {
    dispatch(channelsFetchAwait(ownProps.match.params.organization))
  },
  onMultiTenant: () => dispatch(push('./deployment')),
  onSubmit: data =>
    dispatch(
      channelPlatformConfigure(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        data
      )
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelPlatformConfigureContainer)
