import { useState, useCallback } from 'react'

export const useAsyncAction = (action, onSuccess) => {
  const [result, setResult] = useState(null)
  const [isActing, setIsActing] = useState(true)
  const [error, setError] = useState(null)

  const asyncAction = useCallback(async input => {
    setIsActing(true)
    try {
      const result = await action(input)
      setResult(result)
      setIsActing(false)
      if (onSuccess) await onSuccess()
    } catch (err) {
      setError(err)
      setIsActing(false)
    }
  }, [])

  return [asyncAction, isActing, error, result]
}
