import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { contrastRatio } from 'chromatism'
import MTabs from '@material-ui/core/Tabs'
import MTab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import ErrorMessage from './ErrorMessage'

const tabMap = {
  '1': 'package',
  '2': 'guide'
}

const TabContainer = ({ children }) => (
  <Typography component="div">{children}</Typography>
)

TabContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

class Tabs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      index: 0
    }

    this.handleChange = this.handleChange.bind(this)
  }

  onFileDrop(accepted) {
    const params = {
      file: accepted[0],
      type: tabMap[this.state.index],
      latestVersions: this.props.allLatestVersions
    }
    this.props.onFileDrop(params)
  }

  handleChange(event, index) {
    this.setState({ index })
  }

  handleChangeIndex = index => {
    this.setState({ index })
  }

  render() {
    const background = this.props.color || '#c6c6c6'
    const underline = this.props.colorSecondary || '#9c9c9c'
    const color = contrastRatio(background).hex

    return (
      <Card className="tabs">
        <MTabs
          value={this.state.index}
          onChange={this.handleChange}
          className="header"
          fullWidth
          TabIndicatorProps={{
            className: 'underline'
          }}>
          {this.props.tabs.map((tab, index) => (
            <MTab
              key={tab.title + index}
              label={tab.title}
              className="tab"
              disableRipple
            />
          ))}
        </MTabs>
        {this.props.tabs.map(
          (tab, index) =>
            this.state.index === index && (
              <TabContainer key={tab.title + index}>
                {this.props.edit &&
                  tab.title !== 'Notifications' &&
                  this.props.showUpload && (
                    <div className="files">
                      <Button
                        variant="contained"
                        style={{ backgroundColor: underline, color: '#fff' }}
                        className="upload"
                        onClick={() =>
                          this.props.toggleFileDialog({
                            latestVersions: this.props.allLatestVersions,
                            type: tabMap[this.state.index]
                          })
                        }>
                        Upload content
                      </Button>
                    </div>
                  )}
                {tab.body || (
                  <Dropzone
                    className="dropzone"
                    disabled={!this.props.edit}
                    acceptClassName="drop__accept"
                    onDrop={this.onFileDrop.bind(this)}>
                    <ErrorMessage
                      message={tab.defaultMessage}
                      icon={tab.defaultIcon}
                    />
                  </Dropzone>
                )}
              </TabContainer>
            )
        )}

        <style>{`
          .tabs {
            height: 100%;
            background-color: #fff;
          }
          .tabs .header {
            background-color: ${background} !important;
          }
          .tabs .tab span {
            color: ${color} !important;
            opacity: 0.65;
          }
          .tabs .tab[aria-selected='true'] span {
            opacity: 1;
          }
          .tabs .underline {
            background-color: ${underline} !important;
          }
          .tabs section[tabindex] {
            padding: 0;
          }
          .tabs section:focus {
            outline: 0;
          }
          .tabs .drop__accept {
            border: 1px solid #56ff47;
            border-top: none;
          }
          .tabs .files {
            text-align: center;
            margin: 1rem 0 1rem 0;
          }
          .tabs .errorMessage {
            height: 400px;
          }
          .tabs .files .upload[disabled]{
            background-color: #e5e5e5 !important;
            color: rgba(0,0,0,.26);
          }
        `}</style>
      </Card>
    )
  }
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.element
    })
  ),
  defaultIcon: PropTypes.element,
  defaultMessage: PropTypes.string,
  color: PropTypes.string,
  colorSecondary: PropTypes.string,
  toggleFileDialog: PropTypes.func,
  onFileDrop: PropTypes.func,
  allLatestVersions: PropTypes.object,
  edit: PropTypes.bool,
  showUpload: PropTypes.bool
}

Tabs.defaultProps = {
  showUpload: true
}

export default Tabs
