import React from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import ChannelGlobalPlatformSelectionForm from './ChannelGlobalPlatformSelectionForm'

const ChannelGlobalPlatformSelection = ({
  channel,
  organization,
  platforms,
  onChange,
  onSubmit,
  onRemove,
  selectedPlatform
}) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        {
          title: organization.name,
          href: `/organizations/${organization.id}`
        },
        {
          title: 'Channels',
          href: `/organizations/${organization.id}/channels`
        },
        {
          title: channel.name,
          href: `/organizations/${organization.id}/channels/${channel.id}`
        }
      ]}
    />

    <Card>
      <CardHeader title="Multi-Tenant Deployment" />
      <CardContent className="description">
        {channel.deploymentId ? (
          <>
            <p>
              Removing the deployment will remove any associated course access.
            </p>
            <Button variant="raised" onClick={onRemove} color="secondary">
              Remove Deployment
            </Button>
          </>
        ) : (
          <>
            <p>
              Use this to associate this channel with a deployment of a global
              LTI 1.3 platform. The deployment is generated by the LMS.
            </p>
            <ChannelGlobalPlatformSelectionForm
              platforms={platforms}
              onChange={onChange}
              onSubmit={onSubmit}>
              {selectedPlatform && (
                <div style={{ marginTop: '25px' }}>
                  <div>
                    <strong>Issuer</strong>: {selectedPlatform.issuer}
                  </div>
                  <div>
                    <strong>Client ID</strong>: {selectedPlatform.client_id}
                  </div>
                </div>
              )}
            </ChannelGlobalPlatformSelectionForm>
          </>
        )}
      </CardContent>
    </Card>
  </div>
)

ChannelGlobalPlatformSelection.propTypes = {
  channel: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  platforms: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  selectedPlatform: PropTypes.shape({
    issuer: PropTypes.string.isRequired,
    client_id: PropTypes.string.isRequired
  }),
  onSubmit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}

export default ChannelGlobalPlatformSelection
