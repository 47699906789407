import React from 'react'
import PropTypes from 'prop-types'
import TextInput from '../form/elements/TextInput'
import styled from 'styled-components'
import ToggleInput from '../form/elements/ToggleInput'
import grey from '@material-ui/core/colors/grey'
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon'
import { Button } from '@material-ui/core'
import Terms from './SimulationLicenseTerms'
import SimulationList from './SimulationList'

const LicenseBox = styled.div`
  display: grid;
  gap: 0.75em;
  border-radius: 4px;
  border: 1px solid ${grey[400]};
  padding: 0.5em;
  grid-template-areas:
    'ti ti ti'
    'olk mcu emc'
    'spc pii .'
    '. . .'
    't t t'
    'a a a';

  .olk {
    grid-area: olk;
  }

  .sfa {
    grid-area: sfa;
  }

  .spc {
    grid-area: spc;
  }

  .enl {
    grid-area: enl;
  }

  .mcu {
    grid-area: mcu;
  }

  .emc {
    grid-area: emc;
  }

  .pii {
    grid-area: pii;
  }
`

const ActionsBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  grid-area: a;

  span {
    line-height: normal;
  }
`

const DeleteIcon = styled(TrashCanOutlineIcon)`
  margin-right: 0.5em;
  align-self: start;
`

const SimTitle = styled.span`
  grid-area: ti;
  color: ${grey[600]};
  font-size: 1.1rem;
`

const Actions = ({ olk, deleteLicense }) => {
  return (
    <ActionsBox>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => deleteLicense(olk)}>
        <DeleteIcon />
        Delete
      </Button>
    </ActionsBox>
  )
}

Actions.propTypes = {
  olk: PropTypes.string,
  deleteLicense: PropTypes.func
}

const SimulationLicense = ({ handleLicenseChange, deleteLicense, license }) => {
  const {
    id: licenseId,
    onlineLicenseKey,
    enrollmentLimit,
    maxConcurrentUsers,
    enforceMaxConcurrentUsers,
    sponsorshipCode,
    piiCollection,
    terms,
    productCode
  } = license

  const simulation = SimulationList.find(
    prefix => prefix.licensePrefix === onlineLicenseKey.split('-')[0]
  )

  const { title, productCode: simProductCode } = simulation

  const onLicenseChange = async (id, value) => {
    const updatedLicense = {
      ...license,
      productCode: productCode || simProductCode
    }
    updatedLicense[id] = value
    await handleLicenseChange(onlineLicenseKey, updatedLicense)
  }

  return (
    <LicenseBox>
      <SimTitle>{title}</SimTitle>
      <input type="hidden" id="id" name="id" value={licenseId || ''} />
      <input
        type="hidden"
        id="productCode"
        name="productCode"
        value={productCode || simProductCode}
      />
      <input
        id="enrollmentLimit"
        name="enrollmentLimit"
        value={enrollmentLimit}
        type="hidden"
      />
      <TextInput
        classNames="olk"
        id="onlineLicenseKey"
        label="Online License Key"
        value={onlineLicenseKey}
        onChange={onLicenseChange}
        disabled
      />
      <TextInput
        classNames="spc"
        id="sponsorshipCode"
        label="Sponsorship Code"
        value={sponsorshipCode}
        onChange={onLicenseChange}
      />
      <TextInput
        classNames="mcu"
        id="maxConcurrentUsers"
        label="Max Concurrent Users"
        value={maxConcurrentUsers}
        onChange={(id, value) => onLicenseChange(id, Number(value))}
        type="number"
      />
      <ToggleInput
        classNames="pii"
        id="piiCollection"
        label="Collect Personally Identifiable Information?"
        value={piiCollection}
        onChange={onLicenseChange}
      />
      <ToggleInput
        classNames="emc"
        id="enforceMaxConcurrentUsers"
        label="Enforce Max Concurrent Users"
        value={enforceMaxConcurrentUsers}
        onChange={onLicenseChange}
      />
      <Terms
        terms={terms}
        onLicenseChange={onLicenseChange}
        olk={onlineLicenseKey}
      />
      <Actions olk={onlineLicenseKey} deleteLicense={deleteLicense} />
    </LicenseBox>
  )
}

SimulationLicense.propTypes = {
  license: PropTypes.shape({
    id: PropTypes.string,
    productCode: PropTypes.string,
    onlineLicenseKey: PropTypes.string,
    enrollmentLimit: PropTypes.number,
    terms: PropTypes.arrayOf(
      PropTypes.shape({
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
        specialExtensionEnd: PropTypes.string,
        multiplayerEnd: PropTypes.string
      })
    ),
    maxConcurrentUsers: PropTypes.number,
    enforceMaxConcurrentUsers: PropTypes.bool,
    sponsorshipCode: PropTypes.string,
    piiCollection: PropTypes.bool
  }),
  handleLicenseChange: PropTypes.func,
  deleteLicense: PropTypes.func
}

export default SimulationLicense
