import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { KeyboardDatePicker } from '@material-ui/pickers'

export const DateInput = ({
  id,
  value,
  onChange,
  labelProps,
  buttonProps,
  disabled,
  style = {},
  ...props
}) => {
  const [inputValue, setInputValue] = useState()

  return (
    <KeyboardDatePicker
      style={{ minWidth: '9em', ...style }}
      disabled={disabled}
      autoOk
      disableToolbar
      variant="inline"
      format="yyyy-MM-DD"
      id={id}
      inputValue={inputValue}
      emptyLabel=" "
      value={value || null}
      onChange={(date, value) => {
        setInputValue(value)
        if (date?.isValid()) {
          onChange(id, value)
        }
      }}
      InputLabelProps={labelProps}
      InputAdornmentProps={{
        ...buttonProps,
        style: {
          ...buttonProps?.style,
          visibility: disabled ? 'hidden' : 'inherit'
        }
      }}
      {...props}
    />
  )
}

DateInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  labelProps: PropTypes.object,
  buttonProps: PropTypes.object,
  style: PropTypes.object
}
