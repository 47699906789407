import { fetchChannel } from '../requests/channels'
import { useRequest } from './useRequest'

export const useChannel = (orgId, channelId) => {
  const { result, isFetching, error } = useRequest(
    fetchChannel,
    orgId,
    channelId
  )

  return {
    channel: result,
    isFetchingChannel: isFetching,
    channelError: error
  }
}
