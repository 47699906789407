import axios from 'axios'
import { getToken } from '../auth'

export const globalPlatformsFetch = async () => {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/channels/lti-1.3/global-platforms',
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

export const globalPlatformFetch = async id => {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + `/platforms/global-platforms/${id}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

export async function globalPlatformCreate(data) {
  await axios({
    method: 'POST',
    url: process.env.REACT_APP_REMOTE + '/platforms/global-platforms',
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() },
    data
  })
}

export const globalPlatformUpdate = async (id, data) => {
  await axios({
    method: 'PUT',
    url: process.env.REACT_APP_REMOTE + `/platforms/global-platforms/${id}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() },
    data
  })
}

export const globalPlatformDeploy = async (channel, data) => {
  await axios({
    method: 'PUT',
    url:
      process.env.REACT_APP_REMOTE +
      `/channels/orgs/${channel.organization}/channels/${channel.id}/deployment`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() },
    data
  })
}

export const globalPlatformRemoveDeployment = async channel => {
  await axios({
    method: 'DELETE',
    url:
      process.env.REACT_APP_REMOTE +
      `/channels/orgs/${channel.organization}/channels/${channel.id}/deployment`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
}
