import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import DomainIcon from 'mdi-react/DomainIcon'
import DomainOffIcon from 'mdi-react/DomainOffIcon'
import styled from 'styled-components'

const StatusContainer = styled.div`
  width: fit-content;
  height: 30px;
`

const OrganizationStatus = ({ isActive }) => {
  return (
    <StatusContainer>
      <Tooltip
        title={isActive ? 'Organization is Active' : 'Organization is Inactive'}
        placement="bottom">
        <div>
          {isActive ? (
            <DomainIcon size={30} color="#e67825" />
          ) : (
            <DomainOffIcon size={30} color="darkgray" />
          )}
        </div>
      </Tooltip>
    </StatusContainer>
  )
}

OrganizationStatus.propTypes = {
  isActive: PropTypes.bool
}

export default OrganizationStatus
