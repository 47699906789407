import React from 'react'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import ColorPicker from './ColorPicker'

const CourseMetaForm = props => (
  <div className="course-meta-form">
    <label>
      <ColorPicker
        className="color-picker"
        color={props.course.color}
        onChangeComplete={({ hex }) => props.onChange(hex, 'color')}
      />
      Primary Color
    </label>

    <label>
      <ColorPicker
        className="color-picker"
        color={props.course.colorSecondary}
        onChangeComplete={({ hex }) => props.onChange(hex, 'colorSecondary')}
      />
      Secondary Color
    </label>

    <FormControlLabel
      control={
        <Switch
          color="primary"
          className="switch"
          checked={props.course.published}
          onChange={event => props.onChange(event.target.checked, 'published')}
        />
      }
      label="Published"
    />

    <Button
      variant="contained"
      disabled={props.disableCancel}
      onClick={props.onCancel}>
      Cancel
    </Button>
    <Button
      className="buttonPrimary"
      variant="contained"
      color="primary"
      disabled={props.disableSubmit}
      onClick={props.onSubmit}>
      Save
    </Button>

    {props.isEditing && (
      <div className="loading">
        <CircularProgress size={25} />
      </div>
    )}

    <style>{`
      .course-meta-form {
        display: flex;
        align-items: center;
      }
      .course-meta-form > * {
        margin: 0 10px;
      }
      .course-meta-form label {
        font-size: 14px;
        display: flex;
      }
      .course-meta-form .color-picker {
        margin-top: -2px;
        margin-right: 10px;
      }
      .course-meta-form input[type="color"]::-webkit-color-swatch-wrapper {
        padding: 0;
      }
      .course-meta-form input[type="color"]::-webkit-color-swatch {
        border: none;
      }
      .course-meta-form .buttonPrimary:not([disabled]) {
        background-color: #009BE9 !important;
      }
    `}</style>
  </div>
)

CourseMetaForm.propTypes = {
  course: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool,
  disableCancel: PropTypes.bool,
  isEditing: PropTypes.bool
}

export default CourseMetaForm
