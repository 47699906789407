import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import FormLabel from '@material-ui/core/FormLabel'
import Button from '@material-ui/core/Button'

class SelectInput extends Component {
  static defaultProps = {
    value: '',
    placeholder: '',
    fieldSuffix: '',
    valid: true,
    isMulti: false,
    addAllButton: false,
    label: '',
    options: []
  }

  handleChange = async selectedOption => {
    if (!selectedOption) {
      return
    }
    const { id, onChange, onBlur } = this.props

    if (Array.isArray(selectedOption)) {
      await onChange(
        id,
        selectedOption.map(row => row.value)
      )
    } else {
      const { value } = selectedOption
      await onChange(id, value)
    }

    if (onBlur) onBlur()
  }

  handleAddAll = async () => {
    await this.handleChange(this.props.options)
  }

  getValue(value, options) {
    if (this.props.isMulti) {
      return options.filter(option => value.includes(option.value))
    }
    return options.find(option => value === option.value)
  }

  render() {
    const {
      id,
      value,
      label,
      addAllButton,
      placeholder,
      fieldSuffix,
      valid,
      isSearchable,
      isMulti,
      options
    } = this.props
    const validClass = valid ? '' : 'invalid'
    return (
      <div className="select-input">
        {!!label && <FormLabel>{label}</FormLabel>}
        {addAllButton && (
          <Button size="small" onClick={this.handleAddAll}>
            add all
          </Button>
        )}
        <Select
          id={id}
          styles={{
            control: provided => ({
              ...provided,
              background: '#fff',
              border: '1px solid rgba(63, 81, 181, 0.5)',
              '&:hover': {
                border: '1px solid rgb(63, 81, 181)'
              }
            })
          }}
          value={this.getValue(value, options)}
          placeholder={placeholder}
          className={validClass}
          menuPortalTarget={document.body}
          onChange={this.handleChange}
          options={options}
          isMulti={isMulti}
          autoBlur
          clearable={false}
          isSearchable={isSearchable}
        />
        {fieldSuffix !== '' ? (
          <span className="field-suffix">{fieldSuffix}</span>
        ) : null}
      </div>
    )
  }
}

SelectInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  fieldSuffix: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  valid: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  addAllButton: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
        .isRequired,
      className: PropTypes.string
    })
  )
}

SelectInput.displayName = 'SelectInput'

export default SelectInput
