import { useState, useEffect, useRef } from 'react'
import axios from 'axios'

export const useRequest = (requestFunc, ...requestParams) => {
  const [result, setResult] = useState(null)
  const [isFetching, setIsFetching] = useState(true)
  const [error, setError] = useState(null)

  const sourceRef = useRef(null)

  useEffect(() => {
    sourceRef.current = axios.CancelToken.source()
    ;(async () => {
      try {
        const result = await requestFunc(...requestParams, {
          cancelToken: sourceRef.current.token
        })
        setResult(result)
      } catch (error) {
        console.error(error)
        setError(error)
      } finally {
        setIsFetching(false)
      }
    })()

    return () => {
      if (sourceRef.current) {
        sourceRef.current.cancel('Request canceled due to unmount')
      }
    }
  }, [])

  return { result, isFetching, error }
}
