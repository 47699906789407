import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { Tooltip } from '@material-ui/core'
import FileSyncIcon from 'mdi-react/FileSyncIcon'
import ContentCopyIcon from 'mdi-react/ContentCopyIcon'
import UserAddIcon from 'mdi-react/UserAddIcon'
import CheckCircleIcon from 'mdi-react/CheckCircleIcon'
import SyncCircleIcon from 'mdi-react/SyncCircleIcon'
import InformationCircleIcon from 'mdi-react/InformationCircleIcon'
import Integration from './Integration'
import Status from '../../components/Status'

const BuzzInstance = ({
  buzzInstance,
  title,
  channel,
  syncing,
  canManage,
  canManageBuzzUsers,
  onSync,
  onCopy,
  onCreateBuzzAdmin,
  updateBuzzLtiRegistration,
  checkBuzzLtiRegistration,
  isRegistered,
  isUpdatingBuzzLti
}) => {
  useEffect(() => {
    if (channel[`${buzzInstance}Ids`]) checkBuzzLtiRegistration(buzzInstance)
  }, [])

  return (
    <Integration
      title={title}
      status={
        isUpdatingBuzzLti ? (
          <Status>
            LTI Status
            <SyncCircleIcon />
          </Status>
        ) : isRegistered ? (
          <Tooltip
            title="Buzz LTI 1.3 Registration is Configured"
            placement="left">
            <Status>
              LTI 1.3 Status
              <CheckCircleIcon />
            </Status>
          </Tooltip>
        ) : (
          <Tooltip
            title="Buzz LTI 1.3 Registration is Not Configured, Click 'Update LTI 1.3 Registration'"
            placement="left">
            <Status>
              LTI 1.3 Status
              <InformationCircleIcon />
            </Status>
          </Tooltip>
        )
      }
      configured={!!channel[`${buzzInstance}Ids`]}
      autoSync={channel[`${buzzInstance}Sync`]}
      onSync={() => onSync(buzzInstance)}
      syncing={syncing}
      canManage={canManage}
      body={
        <div>
          <div>ID: {channel[`${buzzInstance}Ids`]}</div>
          <div>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={channel[`${buzzInstance}DisplayDomain`]}>
              {channel[`${buzzInstance}DisplayDomain`]}
            </a>
          </div>
        </div>
      }
      actions={
        <>
          {canManage && (
            <Button onClick={() => onCopy(buzzInstance)}>
              <ContentCopyIcon /> Copy Courses...
            </Button>
          )}
          {canManageBuzzUsers && (
            <Button onClick={() => onCreateBuzzAdmin(buzzInstance)}>
              <UserAddIcon /> Create Account...
            </Button>
          )}
          {canManage && (
            <Button
              onClick={() => {
                if (isRegistered) {
                  if (
                    confirm(
                      'Buzz LTI 1.3 configuration already appears to be updated, continue anyway?'
                    )
                  )
                    updateBuzzLtiRegistration(buzzInstance)
                } else updateBuzzLtiRegistration(buzzInstance)
              }}>
              <FileSyncIcon />
              Update LTI 1.3 Registration
            </Button>
          )}
        </>
      }
    />
  )
}

BuzzInstance.propTypes = {
  buzzInstance: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  channel: PropTypes.object.isRequired,
  canManage: PropTypes.bool.isRequired,
  canManageBuzzUsers: PropTypes.bool.isRequired,
  syncing: PropTypes.bool.isRequired,
  onCopy: PropTypes.func.isRequired,
  onCreateBuzzAdmin: PropTypes.func.isRequired,
  onSync: PropTypes.func.isRequired,
  updateBuzzLtiRegistration: PropTypes.func.isRequired,
  checkBuzzLtiRegistration: PropTypes.func.isRequired,
  isRegistered: PropTypes.bool.isRequired,
  isUpdatingBuzzLti: PropTypes.bool.isRequired
}

export default BuzzInstance
