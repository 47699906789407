import joi from 'joi'
import createForm from '../../components/form/createForm'
import TextInput from '../../components/form/elements/TextInput'
import SelectInput from '../../components/form/elements/SelectInput'

export default ({ platforms, onChange, ...props }) => {
  return createForm({
    fields: [
      {
        id: 'deployment_id',
        label: 'Deployment ID',
        description: 'Enter the deployment created by your LMS',
        inputComponent: TextInput,
        required: true,
        validation: joi.string()
      },
      {
        id: 'platform_id',
        label: 'Global Platform ID',
        inputComponent: SelectInput,
        inputOptions: {
          options: platforms.map(platform => ({
            value: platform.id,
            label: platform.name
          }))
        },
        required: true,
        validation: joi.string()
      }
    ],
    onChange,
    layoutOptions: {
      submitText: 'Save'
    },
    ...props
  })
}
