import React from 'react'
import PropTypes from 'prop-types'
import { each, props as promiseProps } from 'bluebird'
import { noop, debounce, mapValues, kebabCase, uniq } from 'lodash'

import joi from 'joi'
import FormLayout from './FormLayout'
import FieldLayout from './FieldLayout'

const INPUT_PREFIX = 'input-'

export default class DynamicFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.mounted = false
    this.state = this.getDefaultState()
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.handleFieldBlur = this.handleFieldBlur.bind(this)
    const { debounceMs } = this.props
    this.handleChange = debounceMs
      ? debounce(this.handleChange.bind(this), debounceMs)
      : this.handleChange.bind(this)
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  getDefaultState() {
    const { fields } = this.props
    return {
      derivedFieldValues: {},
      submitting: false,
      submissionError: null,
      input: fields.reduce(
        (out, field) => ({
          ...out,
          [field.id]: field.defaultValue === undefined ? '' : field.defaultValue
        }),
        {}
      ),
      validationWarning: null
    }
  }

  /**
   * Handles the submit event
   */
  async handleSubmit(e) {
    e.preventDefault()
    e.stopPropagation()

    const { input } = this.state
    const { onSubmit } = this.props

    if (this.mounted) {
      this.setState({ submitting: true, submissionError: null })
    }

    const data = await this.validate(input, true)

    if (data) {
      try {
        const result = await onSubmit(data)
        if (result && this.mounted) {
          this.setState(this.getDefaultState())
        }
      } catch (e) {
        this.setState({ submissionError: e })
      } finally {
        if (this.mounted) {
          this.setState({ submitting: false })
        }
      }
    }

    if (this.mounted) {
      this.setState({ submitting: false })
    }
  }

  /**
   * Handles a field changing
   */
  async handleFieldChange(field, value) {
    const fieldName = field.substr(INPUT_PREFIX.length)
    await new Promise(resolve => {
      if (this.mounted) {
        this.setState(
          state => ({
            ...state,
            input: {
              ...state.input,
              [fieldName]: value
            }
          }),
          resolve
        )
      }
    })

    await this.handleChange()
  }

  async handleChange() {
    const { onChange, derivedFields } = this.props
    const { input } = this.state
    const derivedFieldValues = await promiseProps(
      mapValues(derivedFields, f => f(input))
    )

    await new Promise(resolve => {
      if (this.mounted) {
        this.setState(
          {
            derivedFieldValues
          },
          resolve
        )
      } else {
        resolve()
      }
    })

    const invalidFields = this.state.validationError
      ? this.state.validationError.details.reduce(
          (out, item) => uniq(out.concat([item.path.join('.')])),
          []
        )
      : []

    onChange({
      ...mapValues(this.state.input, (value, key) =>
        invalidFields.indexOf(key) === -1 ? value : null
      ),
      ...this.state.derivedFieldValues
    })
  }

  /**
   * Blurring any fields causes the form to re-validate
   */
  async handleFieldBlur() {
    const { input } = this.state
    await this.validate(input)
    await this.handleChange()
  }

  /**
   * Validates some input
   *
   * @param {Object} input - current data being validated
   * @param {Boolean} submitting - if true, check all fields
   * @returns {Promise<Object | null>} data formatted to spec of joi validation rules if no errors.
   */
  async validate(input, submitting = false) {
    const { validationRules, derivedFields, fields } = this.props
    const result = validationRules.validate(input, {
      stripUnknown: true,
      abortEarly: false
    })

    const warningMessages = []
    await each(
      fields.filter(f => f.warnings),
      field =>
        each(field.warnings, warning => {
          const message = warning(input)
          if (message) {
            warningMessages.push({
              path: field.id,
              message
            })
          }
        })
    )

    let validationWarning = null
    if (warningMessages.length) {
      validationWarning = submitting
        ? { details: warningMessages }
        : {
            details: warningMessages.filter(
              warning => input[warning.path] !== ''
            )
          }
    }

    const derivedInput = {
      ...result.value,
      ...(await promiseProps(mapValues(derivedFields, f => f(result.value))))
    }

    const checkErrors = []
    await each(
      fields.filter(f => f.checks),
      field =>
        each(field.checks, async check => {
          try {
            await check(derivedInput)
          } catch (e) {
            checkErrors.push({
              path: [field.id],
              message: e.message
            })
          }
        })
    )

    let validationError = null
    // Merge the different errors.
    if (result.error || checkErrors.length) {
      const details = [
        ...(result.error ? result.error.details : []),
        ...(checkErrors.length ? checkErrors : [])
      ]
      validationError = {
        details: submitting
          ? details
          : details.filter(fieldError => {
              const path = this.getErrorPath(fieldError)
              return input[path] !== '' && input[path] !== null
            })
      }
    }
    /* This is kinda hacky, but it's the best way i could come up with for 
    mapping better error messages to the user for the deeply nested licenses 
    given this form solution. */
    if (validationError?.details) {
      validationError.details = validationError?.details.map(detail => {
        const [property, position, field] = Array.isArray(detail.path)
          ? detail.path
          : []
        if (property === 'licenses') {
          const license = input.licenses[position]
          const message = {
            terms: `License must have 1 or more terms assigned to it.`,
            enrollmentLimit: `Enrollment Limit must be -1 or greater.`,
            maxConcurrentUsers:
              'Max concurrent users must be at least 1 or greater.'
          }
          return {
            ...detail,
            message: `License #${position + 1} (with OLK: ${
              license.onlineLicenseKey
            }) is invalid: ${message[field] || 'invalid or missing input'}`
          }
        } else return detail
      })
    }

    // Set state if we're mounted.
    if (this.mounted) {
      this.setState({
        validationError,
        validationWarning
      })
    }
    this.handleChange()

    // Return null if we had errors.
    return validationError ? null : derivedInput
  }

  getErrorPath(error) {
    return Array.isArray(error?.path) ? error.path[0] : error.path
  }

  render() {
    const {
      input,
      submissionError,
      validationError,
      validationWarning,
      submitting
    } = this.state
    const {
      children,
      disabled,
      formComponent: FormComponent,
      fields,
      buttonClassName,
      submitText,
      formClassName,
      layoutOptions
    } = this.props
    const errorsByField = validationError
      ? validationError.details.reduce(
          (out, error) => ({
            ...out,
            [this.getErrorPath(error)]: (
              out[this.getErrorPath(error)] || []
            ).concat([error])
          }),
          {}
        )
      : {}
    const warningsByField = validationWarning
      ? validationWarning.details.reduce(
          (out, warning) => ({
            ...out,
            [this.getErrorPath(warning.path)]: (
              out[this.getErrorPath(warning.path)] || []
            ).concat([warning])
          }),
          {}
        )
      : {}

    const fieldComponents = fields.map(fieldDefinition => {
      const {
        inputComponent: InputComponent,
        inputOptions: options,
        required
      } = fieldDefinition

      const isVisible = fieldDefinition.isVisible
        ? fieldDefinition.isVisible(input)
        : true

      const FieldComponent = fieldDefinition.fieldComponent || FieldLayout
      const fieldOptions = fieldDefinition.fieldOptions || {}
      const inputId = `${INPUT_PREFIX}${fieldDefinition.id}`
      const errors = (errorsByField[fieldDefinition.id] || []).map(
        error => error.message
      )
      const warnings = (warningsByField[fieldDefinition.id] || []).map(
        warning => warning.message
      )

      const inputName = kebabCase(
        InputComponent.displayName || InputComponent.name || 'Unknown'
      )

      return (
        <FieldComponent
          id={fieldDefinition.id}
          key={fieldDefinition.id}
          label={fieldDefinition.label}
          inputId={inputId}
          required={required}
          submissionError={submissionError}
          errorMessages={errors}
          warningMessages={warnings}
          visible={isVisible}
          type={inputName}
          {...fieldOptions}
          {...layoutOptions}>
          <InputComponent
            id={inputId}
            onChange={this.handleFieldChange}
            onBlur={this.handleFieldBlur}
            value={input[fieldDefinition.id]}
            valid={errors.length === 0}
            label={fieldDefinition.label}
            {...options}
          />
        </FieldComponent>
      )
    })

    return (
      <FormComponent
        submitting={submitting}
        error={validationError}
        submissionError={submissionError}
        buttonClassName={buttonClassName}
        submitText={submitText}
        onSubmit={this.handleSubmit}
        onFieldChange={this.handleFieldChange}
        handleFieldBlur={this.handleFieldBlur}
        fieldComponents={fieldComponents}
        formClassName={formClassName}
        disabled={disabled || submitting}
        {...layoutOptions}>
        {children}
      </FormComponent>
    )
  }
}

DynamicFormContainer.defaultProps = {
  formComponent: FormLayout,
  children: '',
  buttonClassName: 'btn',
  submitText: 'Submit',
  disabled: false,
  derivedFields: {},
  validationRules: joi.object({}),
  onChange: noop,
  onSubmit: noop,
  formClassName: '',
  debounceMs: 300,
  layoutOptions: {}
}

DynamicFormContainer.propTypes = {
  formComponent: PropTypes.func,
  layoutOptions: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  validationRules: PropTypes.shape({}),
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      inputComponent: PropTypes.func.isRequired,
      fieldComponent: PropTypes.func,
      inputOptions: PropTypes.shape({})
    })
  ).isRequired,
  derivedFields: PropTypes.objectOf(PropTypes.func),
  children: PropTypes.node,
  buttonClassName: PropTypes.string,
  submitText: PropTypes.string,
  disabled: PropTypes.bool,
  formClassName: PropTypes.string,
  debounceMs: PropTypes.number
}
