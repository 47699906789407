import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FormLabel from '@material-ui/core/FormLabel'
import SimulationLicense from './SimulationLicense'
import SimulationPrefixList from './SimulationList'
import grey from '@material-ui/core/colors/grey'
import PlusIcon from 'mdi-react/PlusIcon'
import { Button } from '@material-ui/core'
import styled from 'styled-components'
import SelectInput from '../form/elements/SelectInput'
import { useLazyGenerateOnlineLicenseKey } from '../../hooks/useLazyGenerateOnlineLicenseKey'
import Spinner from '../Spinner'

const NoLicenses = styled.div`
  border-radius: 4px;
  border: 1px solid ${grey[400]};
  padding: 0.5em;
  width: fit-content;
  color: ${grey[400]};
  background: ${grey[50]};
`

const SimulationLicenseInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  margin: 20px 0;
`

const AddLicenseButton = styled(Button)`
  && {
    min-width: 30%;
    width: fit-content;

    span {
      margin-right: 0.75em;
      line-height: normal;
    }
  }
`

const LicenseActionsBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75em;
  .select-input {
    width: 100%;
  }
`

const NotEnabledMessage = styled.div`
  background: ${grey[50]};
  border: 1px solid ${grey[400]};
  padding: 0.5em;
  border-radius: 4px;
  color: ${grey[600]};
  font-size: 0.9rem;
`

const SimulationLicensesInput = ({
  value: licenses,
  label,
  onChange,
  id,
  enableSimLicenses
}) => {
  const [simType, setSimType] = useState('')
  const [
    generateOnlineLicenseKey,
    { isFetchingOnlineLicenseKey }
  ] = useLazyGenerateOnlineLicenseKey(simType)

  const handleLicenseChange = async (onlineLicenseKey, updatedLicense) => {
    const updatedLicenses = [...licenses].reduce(
      (allLicenses, currentLicense) => {
        if (currentLicense.onlineLicenseKey === onlineLicenseKey) {
          allLicenses.push(updatedLicense)
        } else allLicenses.push(currentLicense)
        return allLicenses
      },
      []
    )
    await onChange(id, updatedLicenses)
  }

  const addNewLicense = async () => {
    const { onlineLicenseKey } = await generateOnlineLicenseKey()
    await onChange(id, [
      ...licenses,
      {
        onlineLicenseKey: onlineLicenseKey,
        piiCollection: true
      }
    ])
  }

  const deleteLicense = async onlineLicenseKey => {
    if (confirm('Are you sure you want to remove this license?')) {
      const updatedLicenses = [...licenses].filter(
        license => license.onlineLicenseKey !== onlineLicenseKey
      )
      await onChange(id, updatedLicenses)
    }
  }

  return (
    <SimulationLicenseInputBox>
      <FormLabel>{label}</FormLabel>
      {enableSimLicenses ? (
        <>
          {licenses.length > 0 ? (
            licenses?.map(license => (
              <SimulationLicense
                key={license.onlineLicenseKey}
                handleLicenseChange={handleLicenseChange}
                deleteLicense={deleteLicense}
                license={license}
              />
            ))
          ) : (
            <NoLicenses>No Simulation Licenses Added To Channel</NoLicenses>
          )}
          <LicenseActionsBox>
            <SelectInput
              placeholder={'Select Simulation'}
              options={SimulationPrefixList.map(sim => ({
                label: sim.title,
                value: sim.licensePrefix
              }))}
              onChange={(id, value) => setSimType(value)}
            />
            <AddLicenseButton
              variant="outlined"
              color="primary"
              onClick={addNewLicense}
              disabled={simType === '' || isFetchingOnlineLicenseKey}>
              {!isFetchingOnlineLicenseKey && (
                <>
                  <PlusIcon /> Add License
                </>
              )}
              {isFetchingOnlineLicenseKey && <Spinner textOnly loading />}
            </AddLicenseButton>
          </LicenseActionsBox>
        </>
      ) : (
        <NotEnabledMessage>
          Licenses cannot be added on channel creation, edit channel after to
          add simulation licenses.
        </NotEnabledMessage>
      )}
    </SimulationLicenseInputBox>
  )
}

SimulationLicensesInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  enableSimLicenses: PropTypes.bool
}

SimulationLicensesInput.defaultProps = {
  label: 'Simulation Licenses'
}

export default SimulationLicensesInput
