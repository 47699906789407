import joi from 'joi'
import createForm from '../../components/form/createForm'
import TextInput from '../../components/form/elements/TextInput'

export default ({
  platform = {},
  includeName = false,
  submitText = '',
  ...props
}) => {
  return createForm({
    fields: [
      includeName
        ? {
            id: 'name',
            label: 'Name',
            inputComponent: TextInput,
            required: true,
            defaultValue: platform.name,
            validation: joi.string()
          }
        : null,
      {
        id: 'client_id',
        label: 'Client ID',
        inputComponent: TextInput,
        required: true,
        defaultValue: platform.client_id,
        validation: joi.string()
      },
      {
        id: 'issuer',
        label: 'Issuer',
        inputComponent: TextInput,
        required: true,
        defaultValue: platform.issuer,
        validation: joi.string()
      },
      {
        id: 'key_set_url',
        label: 'Key Set URL',
        inputComponent: TextInput,
        required: true,
        defaultValue: platform.key_set_url || platform.platform_key_set_url,
        validation: joi.string()
      },
      {
        id: 'auth_token_url',
        label: 'Authentication Token URL',
        inputComponent: TextInput,
        required: true,
        defaultValue: platform.auth_token_url,
        validation: joi.string()
      },
      {
        id: 'auth_login_url',
        label: 'Authentication Login URL',
        inputComponent: TextInput,
        required: true,
        defaultValue: platform.auth_login_url,
        validation: joi.string()
      }
    ].filter(Boolean),
    layoutOptions: {
      submitText: submitText || 'Save Configuration'
    },
    ...props
  })
}
