import React, { useState, useCallback } from 'react'
import ChannelGlobalPlatformSelection from './ChannelGlobalPlatformSelection'
import Screen from '../../components/Screen'
import {
  globalPlatformDeploy,
  globalPlatformRemoveDeployment
} from '../../requests/tools'
import { useOrganization } from '../../hooks/useOrganization'
import { useChannel } from '../../hooks/useChannel'
import { useLtiGlobalPlatforms } from '../../hooks/useLtiGlobalPlatforms'
import { connectAppBar } from '../../hooks/setsAppBar'
import GateExists from '../../components/GateExists'

function GlobalPlatformsContainer({ match, setAppBar, history }) {
  setAppBar('LTI 1.3 Global Platforms')
  const organizationId = match.params.organization
  const channelId = match.params.channel

  const {
    organization,
    isLoadingOrganization,
    errorOrganization
  } = useOrganization(organizationId)

  const { channel, isLoadingChannel, errorChannel } = useChannel(
    organizationId,
    channelId
  )

  const {
    globalPlatforms,
    isFetchingGlobalPlatforms,
    globalPlatformsError
  } = useLtiGlobalPlatforms()

  const handleSubmit = useCallback(
    async data => {
      await globalPlatformDeploy(channel, data)
      alert('Deployment added')
      history.push(
        `/organizations/${organizationId}/channels/${channelId}/platform-configuration`
      )
    },
    [channel]
  )

  const handleRemove = useCallback(async () => {
    await globalPlatformRemoveDeployment(channel)
    alert('Deployment removed')
    history.push(
      `/organizations/${organizationId}/channels/${channelId}/platform-configuration`
    )
  }, [channel])

  const [selected, setSelected] = useState(null, [])
  const handleChange = useCallback(data => setSelected(data.platform_id), [])
  const selectedPlatform = selected
    ? globalPlatforms.find(row => row.id === selected)
    : null

  const isLoading =
    isLoadingOrganization || isLoadingChannel || isFetchingGlobalPlatforms
  const error = errorOrganization || errorChannel || globalPlatformsError
  return (
    <Screen name="global platform selector" isLoading={isLoading} error={error}>
      <GateExists exists={channel && organization}>
        <ChannelGlobalPlatformSelection
          channel={channel}
          organization={organization}
          platforms={globalPlatforms}
          selectedPlatform={selectedPlatform}
          onChange={handleChange}
          onSubmit={handleSubmit}
          onRemove={handleRemove}
        />
      </GateExists>
    </Screen>
  )
}

export default connectAppBar(GlobalPlatformsContainer)
