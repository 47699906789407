import { debounce } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  divider: {
    height: theme.spacing(2)
  }
})

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node)
          inputRef(node)
        },
        classes: {
          input: classes.input
        }
      }}
      {...other}
    />
  )
}

class SearchQueryInput extends React.Component {
  constructor() {
    super()

    this.state = {
      error: null,
      suggestions: [],
      isLoading: false
    }

    this.debouncedLoadSuggestions = debounce(
      this.loadSuggestions.bind(this),
      250
    )
  }

  loadSuggestions = value => {
    this.props
      .getSuggestions(value)
      .then(suggestions => this.setState({ suggestions, isLoading: false }))
      .catch(error => this.setState({ error }))
  }

  handleChange = (event, { newValue }) => {
    this.props.onChange(newValue)
  }

  handlePaste = event => {
    const rawText = event.clipboardData.getData('text')
    if (rawText.includes('\n')) {
      event.preventDefault()
      const ids = rawText.split('\n').filter(row => row.length)
      ids.forEach(id => this.props.onSelect(id.trim()))
    }
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({ isLoading: true })
    this.debouncedLoadSuggestions(value)
  }

  handleSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] })
  }

  handleSelect = (e, { suggestionValue }) => {
    this.props.onSelect(suggestionValue)
  }

  render() {
    const {
      classes,
      renderSuggestion,
      getSuggestionValue,
      type,
      value
    } = this.props
    const { suggestions, isLoading, error } = this.state
    const inputProps = {
      classes,
      placeholder: `Type to find a ${type}`,
      value,
      onChange: this.handleChange,
      onPaste: this.handlePaste
    }

    return (
      <div className={classes.root + (isLoading ? ' suggesting' : '')}>
        <div>{error ? error.message : ''}</div>
        <Autosuggest
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          onSuggestionSelected={this.handleSelect}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={suggestion => (
            <MenuItem component="div">{renderSuggestion(suggestion)}</MenuItem>
          )}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
          renderInputComponent={renderInputComponent}
          highlightFirstSuggestion
          inputProps={inputProps}
        />
      </div>
    )
  }
}

SearchQueryInput.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    container: PropTypes.string,
    suggestionsContainerOpen: PropTypes.string,
    suggestionsList: PropTypes.string,
    suggestion: PropTypes.string
  }),
  value: PropTypes.string,
  type: PropTypes.string.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.func.isRequired,
  getSuggestionValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
}

SearchQueryInput.defaulProps = {
  value: ''
}

export default withStyles(styles)(SearchQueryInput)
